import { Button } from "@mui/material"
import { useForms } from "hooks"
import { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { getSessionStorage } from "./functions"
import pagesretour from "./json/pagesretour.json"

const ButtonPayment = ({ keyform, school }) => {

  const { handleSubmit, trigger, watch } = useFormContext()
  const { salesforce } = useForms()
  const stageName = watch('opportunity.StageName')
  const opportunityId = watch('opportunity.Id')
  const sessionId = watch('opportunity.Session__c')
  const refForm = useRef()
  const [payboxData, setPayboxData] = useState({})

  const goToBank = async data => {
    const isValid = await trigger()
    if (isValid) {
      if (stageName === 'En cours') {
        const opportunity = {
          Id: data.opportunity.Id,
          StageName: 'Candidature à valider',
        }

        await salesforce.updateOpportunity(opportunity)
      }
      sessionStorage.clear()
      refForm.current.submit()
    }
  }

  useEffect(() => {
    if (salesforce !== null) {
      const getData = async () => {
        const localAccount = getSessionStorage(keyform, 'formData')
        const account = {
          Id: localAccount.account.Id,
          FirstName: localAccount.account.FirstName,
          LastName: localAccount.account.LastName,
          PersonEmail: localAccount.account.PersonEmail,
          PersonMobilePhone: localAccount.account.PersonMobilePhone,
          PersonMailingCity: localAccount.account.PersonMailingCity,
          PersonMailingCountry: localAccount.account.PersonMailingCountry,
          PersonMailingPostalCode: localAccount.account.PersonMailingPostalCode,
          PersonMailingStreet: localAccount.account.PersonMailingStreet
        }

        const cycle = await salesforce.getCycle(sessionId)

        const { pages : { effectue, annule, refuse } } = pagesretour.find(page => page.school === school && cycle === page.cycle)

        const payboxData = await salesforce.getPayboxData(account, school.replace(/'/g, ''), { paiement_ok: effectue, paiement_refuse: refuse, paiement_annule: annule, paiement_traitement: process.env.REACT_APP_PAYBOX_TRAITEMENT }, opportunityId)

        return { payboxData }
      }

      getData().then((value) => {
        const { payboxData } = value
        setPayboxData(payboxData)
      })
    }
  }, [salesforce])

  return (
    <>
      <form
        method="POST"
        action={process.env.REACT_APP_PAYBOX_URL}
        ref={refForm}
      >
        <input type="hidden" name="PBX_SITE" value={payboxData.pbxSite} />
        <input type="hidden" name="PBX_RANG" value={payboxData.pbxRang} />
        <input type="hidden" name="PBX_IDENTIFIANT" value={payboxData.pbxIdentifiant} />
        <input type="hidden" name="PBX_TOTAL" value={payboxData.pbxTotal} />
        <input type="hidden" name="PBX_DEVISE" value={payboxData.pbxDevise} />
        <input type="hidden" name="PBX_CMD" value={payboxData.pbxCmd} />
        <input type="hidden" name="PBX_SHOPPINGCART" value={payboxData.pbxShoppingCart} />
        <input type="hidden" name="PBX_PORTEUR" value={payboxData.pbxPorteur} />
        <input type="hidden" name="PBX_BILLING" value={payboxData.pbxBilling} />
        <input type="hidden" name="PBX_RETOUR" value={payboxData.pbxRetour} />
        <input type="hidden" name="PBX_HASH" value={payboxData.pbxHash} />
        <input type="hidden" name="PBX_TIME" value={payboxData.pbxTime} />
        <input type="hidden" name="PBX_EFFECTUE" value={payboxData.pbxEffectue} />
        <input type="hidden" name="PBX_REFUSE" value={payboxData.pbxRefuse} />
        <input type="hidden" name="PBX_ANNULE" value={payboxData.pbxAnnule} />
        <input type="hidden" name="PBX_REPONDRE_A" value={payboxData.pbxRepondreA} />
        <input type="hidden" name="PBX_HMAC" value={payboxData.pbxHmac} />

        <Button color="primary" startIcon={<CreditCardIcon />} onClick={handleSubmit(goToBank)} variant="contained">Payer</Button>
      </form>
    </>
  )
}

export default ButtonPayment
