import { VisibilityOff, Visibility } from "@mui/icons-material"
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import { useState } from "react"
import { Controller } from "react-hook-form"

const InputPassword = ({ control, label, name, ...rest }) => {
  const { required } = rest

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleMouseUpPassword = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (
          <FormControl fullWidth error={!!error} required={required}>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
              {...rest}
              aria-label={label}
              error={!!error}
              id={name}
              label={label}
              inputProps={{
                autoComplete: 'new-password',
              }}
              name={name}
              onChange={onChange}
              required={required}
              size="small"
              type={showPassword ? 'text' : 'password'}
              value={value}
              variant="outlined"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword ? 'hide the password' : 'display the password'
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                    tabIndex={-1}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>{error ? error.message : null}</FormHelperText>
          </FormControl>
        )}
        rules={rest}
      />
    </>
  )
}

export default InputPassword
