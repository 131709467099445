import React, { useEffect, useState } from 'react'
import { useStepContext } from './useStepForm'
import { Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const StepRenderer = ({ defaultCycle = '', formation = '', keyform = '', school = '', setSessionsCb }) => {
  const { currentConfig } = useStepContext()
  const [StepComponent, setStepComponent] = useState(null)

  useEffect(() => {
    const loadComponent = async () => {
      try {
        const component = await import(`./Steps/${currentConfig.component}`)
        setStepComponent(() => component.default)
      } catch (error) {
        console.error(error)
      }
    }

    loadComponent()
  }, [currentConfig])

  if (!StepComponent) {
    return (
      <>
        <Grid container justifyContent="center" alignItems="center" sx={{ mt: 10}}>
          <CircularProgress />
        </Grid>
      </>
    )
  }

  return (
    <StepComponent
      defaultCycle={defaultCycle}
      formation={formation}
      keyform={keyform}
      school={school}
      setSessionsCb={setSessionsCb}
    />
  )
}

export default StepRenderer
