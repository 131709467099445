// import { createTheme } from "@mui/material/styles"
// import { defaultTheme } from "components/Theme/Style"

// const theme = createTheme(defaultTheme, {
//   palette: { mode: 'light', primary: { main: '#17418F', dark: '#102d64' }, secondary: { main: '#ff4d4d' }, neutral: { main: '#5c6ac4' } }
// })

// export default theme

import { createTheme } from "@mui/material/styles"
import { defaultTheme } from "components/Theme/Style"

const customTheme = (primaryColor = '#FFF') => {
  let themeOptions = {
    palette: { mode: 'light', primary: { main: '#102B65' }, secondary: { main: '#ff4d4d' }, neutral: { main: '#5c6ac4' } }
  }

  if (primaryColor !== '#FFF') {
    themeOptions = {
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: primaryColor
            }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ffffff', // Couleur de la bordure
                }
              },
              '& .MuiInputLabel-root': {
                color: '#ffffff', // Couleur du label
              },
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              '&.Mui-disabled': {
                color: '#7A8CA0', // couleur du texte quand disabled
              },
            }
          }
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              color: '#ffffff', // Couleur de l'icone
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: '#ffffff', // Couleur de la bordure
            },
            root: {
              '&.Mui-disabled': {
                backgroundColor: '#0A2544', // couleur de fond quand disabled
                color: '#7A8CA0', // couleur du texte quand disabled

                // Pour que la couleur du texte s’applique bien :
                '& .MuiSelect-select': {
                  color: '#7A8CA0',
                },

                // Pour que le label désactivé ait aussi la bonne couleur :
                '& .MuiInputLabel-root.Mui-disabled': {
                  color: '#5C6B7D',
                },

                // Et aussi la bordure (optionnel) :
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2A3B55',
                },
                '& .MuiSelect-icon': {
                  color: '#5C6B7D',
                },
              },
            },
          }
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              color: '#ffffff', // Couleur du label
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: '#ffffff', // Couleur du label
            },
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              color: '#ffffff', // Couleur du label
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              color: '#102B65', // Couleur du label
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              color: '#ffffff', // Couleur du label
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              '&.MuiTelInput-Typography-calling-code': {
                color: '#FFFFFF',
              },
            },
          },
        },
      },
      palette: { mode: 'light', primary: { main: '#00BCF2', dark: '#33C9F5' }, secondary: { main: '#ff4d4d' }, neutral: { main: '#5c6ac4' }, text: { primary: '#FFF' }, error: { main: '#FF5733' } }
    }
  }

  return createTheme(defaultTheme, themeOptions)
}

export { customTheme }
