import PropTypes from "prop-types"
import { MenuItem, ListSubheader } from "@mui/material"
import { Controller } from "react-hook-form"
import DropdownCommon from "./DropdownCommon"

/**
 * @param {Array} options - Liste d’objets contenant au minimum `value`, `label`, et le champ groupBy
 * @param {string} groupBy - Nom du champ qui sert à grouper
 * @param {Function} getGroupLabel - Fonction qui transforme une clé brute en libellé de groupe
 */
const DropdownGrouping = ({ control, label, name, onValueChange, options, groupBy, getGroupLabel, ...rest }) => {
  const renderGroupedOptions = () => {
    const grouped = options.reduce((acc, item) => {
      const groupKey = item[groupBy] || "Autres"
      if (!acc[groupKey]) acc[groupKey] = []
      acc[groupKey].push(item)
      return acc
    }, {})

    return Object.entries(grouped).map(([group, items]) => (
      [
        <ListSubheader key={`header-${group}`}>
          {getGroupLabel ? getGroupLabel(group) : group}
        </ListSubheader>,
        ...items.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )),
      ]
    )).flat() // flatten to avoid nested arrays
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <DropdownCommon
          {...rest}
          error={error}
          label={label}
          name={name}
          onChange={(event) => {
            const selectedValue = event.target.value
            onChange(selectedValue)
            if (onValueChange) {
              onValueChange(selectedValue)
            }
          }}
          renderOptions={renderGroupedOptions()}
          value={value}
        />
      )}
    />
  )
}

DropdownGrouping.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  groupBy: PropTypes.string.isRequired,
  getGroupLabel: PropTypes.func,
  onValueChange: PropTypes.func,
}

export default DropdownGrouping
