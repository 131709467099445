import { FormProvider, useForm } from "react-hook-form"
import { getSessionStorage, setSessionStorage } from "./functions"
import { useForms, useSalesforce } from "hooks"
import { useState } from "react"
import schemas from "./Validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import { DevTool } from "@hookform/devtools"
import Navigation from "./Navigation"
import { Grid } from "@mui/material"
import queryString from "query-string"
import StepRenderer from "./StepRenderer"
import { useStepContext } from "./useStepForm"
import Buttons from "./Buttons"

const Form = ({ defaultCycle = '', keyform = '', formation = '', school = '' }) => {

  const { salesforce, setError, setOpenSnackbar } = useForms()
  const { formValues, setCurrentStep, setFormValue } = useStepContext()
  const { currentStep, forgotPassword, payLater, userExist, wantLogin } = formValues

  // Données sauvegardées dans le local storage
  const savedData = getSessionStorage(keyform, 'formData')

  // Token
  const { security_token, token } = queryString.parse(document.location.search)

  // State
  const [recordTypeIdOppy, setRecordTypeIdOppy] = useState('')

  // Valeurs par défaut
  const defaultValues = {
    account: {
      AcademicLevel__pc: '',
      FirstName: '',
      Grant_Student__c: false,
      LastName: '',
      MarketingOptIn__c: false,
      PersonEmail: '',
      PersonMailingCity: '',
      PersonMailingCountry: '',
      PersonMailingPostalCode: '',
      PersonMailingStreet: '',
      PersonMobilePhone: '',
      SchoolSupCity__c: '',
      SchoolSupCountry__c: '',
      SchoolSupName__c: ''
    },
    opportunity: {
      CampusCityWish__c: '',
      City_in_charge__c: '',
      ConvocationDate__c: '',
      ConvocationHour__c: '',
      Session__c: '',
      SkypeInterview__c: false
    },
    CGV: false,
    cycle: '',
    files: [],
    filesUploaded: [],
    password: '',
    passwordConfirmation: '',
    programme: '',
    slot: ''
  }

  // On merge les valeurs par défaut avec les valeurs sauvegardées
  const initialValues = { ...defaultValues, ...savedData }
  // Schéma de validation
  const schema = schemas[currentStep]

  // Initialisation du formulaire
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    context: { forgotPassword, payLater, userExist, wantLogin }
  })

  const { control, setValue, watch } = methods

  // Watch

  // useEffect

  // Sauvegarde des données dans le local storage
  useEffect(() => {
    // Sauvegarder dans localStorage chaque fois que les valeurs du formulaire changent
    const filteredData = (data) => {
      // Exclure les champs contenant des fichiers
      const { files, ...filtered } = data;
      return filtered;
    }

    const subscription = watch((value) => {
      const filteredFormData = filteredData(value)
      setSessionStorage(keyform, { formData: filteredFormData })
    })

    // Cleanup
    return () => subscription.unsubscribe()
  }, [watch, currentStep])

  useEffect(() => {
    if (salesforce !== null) {
      const getData = async () => {
        const recordTypeId = await salesforce.getRecordTypeId('Engineering Schools Application')

        return { recordTypeId }
      }

      getData().then((value) => {
        const { recordTypeId } = value
        setRecordTypeIdOppy(recordTypeId)
        // setOpenBackdrop(false)
      }).catch((error) => {
        setError('Une erreur est survenue lors du chargement du formulaire', error)
      })
    }
  }, [salesforce, setError])

  // Vérification de l'email via le token
  useEffect(() => {
    if (token !== undefined && token !== '' && salesforce !== null) {
      setCurrentStep(1)
      const getData = async () => {
        const account = await salesforce.verifyConfirmationToken('Inge', token)
        const opportunities = await salesforce.getOpportunities(account.Id)
        const opportunity = {
          Id: opportunities[0].Id,
          StageStop__c: 2
        }
        await salesforce.updateOpportunity(opportunity)
        await salesforce.updateLastConnection(account.Id)

        return { account, opportunities }
      }

      getData().then((value) => {
        const { account, opportunities } = value
        setFormValue('connected', true)
        if (account.AcademicLevel__pc) {
          account.AcademicLevel__pc = account.AcademicLevel__pc.split('_')[0]
        }
        // On recupère les informations de l'utilisateur
        setValue('account',account)
        setValue('opportunity', opportunities[0])
        setCurrentStep(2)
        const queryParams = queryString.parse(document.location.search)
        const url = window.location.href
        const [baseUrl] = url.split('?')
        if (queryParams.token) {
          delete queryParams.token
          const queryStringified = queryString.stringify(queryParams)
          const newUrl = queryStringified ? `${baseUrl}?${queryStringified}` : baseUrl
          window.history.replaceState({}, '', newUrl)
        }
        // setOpenBackdrop(false)
      }).catch((error) => {
        setError('Une erreur est survenue lors de la validation du token', error)
      })
    }
  }, [salesforce, token])

  // Pour mettre à jour le mot de passe
  useEffect(() => {
    if (security_token !== undefined && security_token !== '') {
      setCurrentStep(1)
      setFormValue('wantLogin', false)
      setFormValue('forgotPassword', false)
      setFormValue('userExist', false)
      setFormValue('wantChangePassword', true)
    }
  }, [security_token])

  // Récupération de l'ID du record type
  useSalesforce('EngineeringSchoolApplicant')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Navigation />
      <Grid container spacing={2}>
        <StepRenderer defaultCycle={defaultCycle} formation={formation} keyform={keyform} school={school} />
        <Buttons keyform={keyform} recordTypeIdOppy={recordTypeIdOppy} school={school} token={token} />
      </Grid>
    </FormProvider>
  )

}

export default Form
