const stepConfigurations = [
  {
    component: 'Formations',
    stepName: 'Choix de formation',
    onNext: async (data, config) => {
      const { cycle, recordTypeId, recordTypeIdOppy, salesforce, school, sessions } = config
      const email = data.account.PersonEmail
      const { exist : userExist, ionisConnectId } =  await salesforce.checkUser(email, school, 'Inge')
      // On vérifie si il a déjà un compte
      const existingAccount = await salesforce.getAccountExisting(email, school)

      let existingId = ''
      let existingOppyId = ''
      let accountVariable = {}
      let updatedAccount = ''

      if (existingAccount.account !== undefined) {
        existingId = existingAccount.account.Id
        existingOppyId = existingAccount.opportunities[0]?.Id

        accountVariable = {
          ...accountVariable,
          Id: existingId,
        }
      } else {
        accountVariable = {
          ...accountVariable,
          Division__pc: 'Inge',
          IONIS_School__pc: 'Inge',
          RecordTypeId: recordTypeId,
          StageStop__c: 'infos'
        }
      }

      if (school === 'EPITA') {
        accountVariable = {
          ...accountVariable,
          EpitaApplication__c: true
        }
      }
      if (school === 'ESME') {
        accountVariable = {
          ...accountVariable,
          ESMEApplication__c: true
        }
      }
      if (school === 'IPSA') {
        accountVariable = {
          ...accountVariable,
          IPSAApplication__c: true
        }
      }
      if (school === `Sup'Biotech`) {
        accountVariable = {
          ...accountVariable,
          SupbiotechApplication__c: true
        }
      }

      // Si le IonisConnect existe déjà (et validé) mais que le compte n'existe pas. On l'associe au compte
      if (userExist && existingAccount.account === undefined) {
        accountVariable = {
          ...accountVariable,
          IonisConnect__c: ionisConnectId
        }
      }

      const account = { ...data.account, ...accountVariable }

      if (existingAccount.account === undefined) {
        updatedAccount = await salesforce.updateAccount(account)
      }

      let competitionType = ''
      let paralleleAdmission = false

      if (!existingOppyId) {
        switch(cycle) {
          case 'Cycle Bachelor':
            competitionType = 'Bachelor'
            break
          case 'Cycle master':
            competitionType = 'MSC et MBA'
            break
          default:
            competitionType = 'Advance Para'
            paralleleAdmission = true
            break
        }

        const fullSession = sessions.find(session => session.Id === data.opportunity.Session__c)

        const opportunity = {
          AccountId: updatedAccount.Id || existingAccount.account.Id,
          BachelorProgramm__c: cycle === 'Cycle Bachelor',
          BackToBackSession__c: fullSession.MidYearSession__c,
          CampusCityWish__c: data.opportunity.CampusCityWish__c,
          City_in_charge__c: data.opportunity.City_in_charge__c,
          CompetitionType__c: data.opportunity.CompetitionType__c || competitionType,
          CloseDate: new Date(),
          InternationalSession__c: fullSession.Division__c.includes('International'),
          LearningSession__c: fullSession.FormationType__c === 'Apprenti',
          Name: 'Candidature',
          OnlineApplication__c: true,
          ParallelAdmissionSession__c: paralleleAdmission && fullSession.FormationType__c === 'Classique',
          RecordTypeId: recordTypeIdOppy,
          Session__c: data.opportunity.Session__c,
          StageName: 'En cours',
          StageStop__c: 1
        }

        if (existingAccount.opportunities.length === 0) {
          await salesforce.updateOpportunity(opportunity)
        }
      }
      const stepNext = true

      return { data, stepNext, userExist }
    }
  },
  {
    component: 'IonisConnect',
    stepName: 'Connexion / Création du compte',
    onNext: async (data, config) => {
      const { salesforce, recordTypeId, school, userExist } = config
      let stepNext = false
      let noOpportunity = false
      let wrongPassword = false

      const user = {
        email: data.account.PersonEmail,
        password: data.password
      }

      if (!userExist) {
        // Création du IonisConnect
        const ionisConnectCreated = await salesforce.createUser('Inge', document.location.href, recordTypeId, school.replace(/'/g, ''), '', user)
        const ionisConnectToValidated = true
        return { ionisConnectCreated, ionisConnectToValidated }
      } else {
        // Connexion de l'utilisateur
        let ionisConnectId = ''
        try {
          ionisConnectId = await salesforce.verifyUser(data.account.PersonEmail, data.password, school, 'Inge')
        } catch (error) {
          console.log(error)
          wrongPassword = true
          return { wrongPassword }
        }
        const { account } = await salesforce.getAccount(data.account.PersonEmail, ionisConnectId, 'Inge')
        if (account.AcademicLevel__pc) {
          account.AcademicLevel__pc = account.AcademicLevel__pc.split('_')[0]
        }
        // Récupération des opportunités
        const opportunities = await salesforce.getOpportunities(account.Id)
        // On récupère l'opportunité qui est ni de CPGE, ni de Parcoursup
        const opportunity = opportunities.find(opportunity => opportunity.CompetitionType__c !== 'CPGE' && opportunity.CompetitionType__c !== 'Parcoursup' && opportunity.Division__c === school)
        if (opportunity === undefined) {
          noOpportunity = true
          return { noOpportunity, stepNext }
        }
        // On va récupérer le cycle correspond à la session de l'utilisateur
        const cycle = await salesforce.getCycle(opportunity.Session__c)
        const isConnected = true
        if (parseInt(opportunity.StageStop__c) === 1) {
          stepNext = true
        }
        return { account, cycle, isConnected, opportunity, stepNext }
      }

    }
  },
  {
    component: 'Informations',
    stepName: 'Informations personnelles',
    onNext: async (data, config) => {
      const { salesforce } = config
      let stepNext = false
      // Mise à jour du compte
      await salesforce.updateAccount(data.account)
      // Mise à jour de l'opportunité
      // const opportunityUpdated = await salesforce.updateOpportunity(data.opportunity)
      await salesforce.updateStageStop(data.opportunity.Id, 3)
      stepNext = true

      return { stepNext }
    }
  },
  {
    component: 'Pieces',
    stepName: 'Pièces jointes',
    onNext: async (data, config) => {
      const { salesforce } = config
      let stepNext = false
      try {
        if (data.files.length > 0) {
          const attachments = await salesforce.addAttachmentToSobject(data.files, data.account.Id)
          for (let i = 0; i < attachments.length; i++) {
            await salesforce.checkAttachment(attachments[i].batchId, attachments[i].jobId)
            await salesforce.resultAttachment(attachments[i].batchId, attachments[i].jobId)
          }
          // setValue('files', null)
        }
        await salesforce.updateStageStop(data.opportunity.Id, 4)
        stepNext = true
      } catch (error) {
        console.log(error)
      }

      return { stepNext }
    }
  },
  {
    component: 'Paiement',
    stepName: 'Paiement',
    onNext: async (data, config) => {
      const { salesforce } = config
      const stepNext = false
      // await salesforce.updateStageStop(data.opportunity.Id, 5)

      return { stepNext }
    }
  }
]

export default stepConfigurations
