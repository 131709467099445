import { createContext, useCallback, useContext, useState } from "react"
import { getSessionStorage, setSessionStorage } from "./functions"
import { useForms } from "hooks"

const StepContext = createContext({
  currentStep: 0,
  currentConfig: {},
  formValues: {},
  handleNext: async () => {},
  handleBack: () => {},
  loading: false,
  sessions: [],
  setCurrentStep: () => {},
  setFormValue: () => {},
  setLoading: () => {},
  setSessions: () => {},
  stepNames: []
})

const useStepForm = (keyform, initialConfig) => {
  const [config, setConfig] = useState(initialConfig)
  const configLength = config.length
  const [loading, setLoading] = useState(false)
  const { recordTypeId, salesforce } = useForms()
  const [sessions, setSessions] = useState([])
  const [formValues, setFormValues] = useState(() => {
    const savedValues = getSessionStorage(keyform, 'formValues')
    return savedValues ? savedValues : {
      currentStep: 0,
      blocked: false,
      connected: false,
      forgotPassword: false,
      ionisConnectToValidated: false,
      noOpportunity: false,
      passwordChanged: false,
      payLater: false,
      resetPasswordMailSent: false,
      userExist: false,
      wantChangePassword: false,
      wantLogin: false,
      wrongPassword: false
    }
  })

  const setFormValue = useCallback((key, value) => {
    setFormValues((prev) => {
      const newValues = { ...prev, [key]: value }
      setSessionStorage(keyform, { formValues: newValues })
      return newValues
    })
  }, [keyform])

  const setCurrentStep = useCallback((stepUpdater) => {
    setFormValues((prev) => {
      const currentStep = prev.currentStep
      const newStep = typeof stepUpdater === 'function' ? stepUpdater(currentStep) : stepUpdater
      const newValues = { ...prev, currentStep: newStep }
      setSessionStorage(keyform, { formValues: newValues })
      return newValues
    })
  }, [keyform])

  const handleNext = async (data, configs) => {
    setLoading(true)
    setFormValue('forgotPassword', false)
    setFormValue('ionisConnectToValidated', false)
    setFormValue('noOpportunity', false)
    setFormValue('passwordChanged', false)
    setFormValue('payLater', false)
    setFormValue('resetPasswordMailSent', false)
    setFormValue('wantChangePassword', false)
    setFormValue('wrongPassword', false)
    try {
      if (configLength > formValues.currentStep + 1) {
        const currentConfig = config[formValues.currentStep]
        const result = await currentConfig.onNext(data, { ...configs, sessions, recordTypeId, salesforce, userExist: formValues.userExist })
        const { account, cycle, isConnected, opportunity } = result
        if (isConnected !== undefined) {
          setFormValue('connected', isConnected)
          setCurrentStep(parseInt(opportunity.StageStop__c))
        }
        if (result.userExist !== undefined) {
          setFormValue('userExist', result.userExist)
        }
        if (result.stepNext) {
          setCurrentStep((prevStep) => prevStep + 1)
        }
        if (result.ionisConnectToValidated) {
          setFormValue('ionisConnectToValidated', result.ionisConnectToValidated)
        }
        if (result.wrongPassword) {
          setFormValue('wrongPassword', result.wrongPassword)
        }
        if (result.noOpportunity) {
          setFormValue('noOpportunity', result.noOpportunity)
          setFormValue('wantLogin', false)
          setCurrentStep(0)
        } else {
          setFormValue('noOpportunity', result.noOpportunity)
        }
        setLoading(false)
        return { account, cycle, opportunity }
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      console.log(error.message)
      if (error.message === 'Impossible de récupérer les données utilisateurs') {
        console.log('maj')
        setFormValue('noOpportunity', true)
        setCurrentStep(0)
      }
    }
  }

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }

  const stepNames = config.map((step) => step.stepName)

  return {
    currentStep: formValues.currentStep,
    currentConfig: config[formValues.currentStep],
    formValues,
    handleNext,
    handleBack,
    loading,
    sessions,
    setCurrentStep,
    setFormValue,
    setLoading,
    setSessions,
    stepNames
  }
}

export const StepProvider = ({ children, keyform, initialConfig }) => {
  const stepForm = useStepForm(keyform, initialConfig)

  return (
    <StepContext.Provider value={stepForm}>
      {children}
    </StepContext.Provider>
  )
}

export const useStepContext = () => { return useContext(StepContext) }
