const getSessionStorage = (keyform, key) => {
  const data = JSON.parse(sessionStorage.getItem(keyform) || '{}')
  return data[key]
}

const setSessionStorage = (key, data) => {
  const existingData = JSON.parse(sessionStorage.getItem(key) || '{}')
  const newData = { ...existingData, ...data }
  sessionStorage.setItem(key, JSON.stringify(newData))
}

const sortByName = (a, b) => {
  const nameA = a.label.toLowerCase() // Assurer que la comparaison est insensible à la casse
  const nameB = b.label.toLowerCase()
  if (nameA < nameB) return -1  // Si a < b, a vient avant b
  if (nameA > nameB) return 1   // Si a > b, b vient avant a
  return 0  // Si les deux sont égaux
}

export { getSessionStorage, setSessionStorage, sortByName }
