import { LoadingButton } from "@mui/lab"
import { Grid } from "@mui/material"
import { useStepContext } from "./useStepForm"
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigatePrevIcon from '@mui/icons-material/NavigateBefore'
import { useFormContext } from "react-hook-form"
import { useForms } from "hooks"
import queryString from "query-string"
import ButtonPayment from "./ButtonPayment"
import pagesretour from "./json/pagesretour.json"

const Buttons = ({ keyform, recordTypeIdOppy, school }) => {

  const { getValues, handleSubmit, setValue, watch } = useFormContext()
  const { salesforce } = useForms()
  const { formValues, handleBack, handleNext, loading, setFormValue, setLoading } = useStepContext()
  const { blocked, connected, currentStep, forgotPassword, ionisConnectToValidated, userExist, wantChangePassword } = formValues
  const { token } = queryString.parse(document.location.search)

  const ApplicationPayment = watch('opportunity.ApplicationPayment__c')
  const cycle = watch('cycle')
  const boursier = watch('account.Grant_Student__c')
  const stageName = watch('opportunity.StageName')
  const sessionId = watch('opportunity.Session__c')

  const { security_token } = queryString.parse(document.location.search)

  const onNextClick = async (data) => {
    const updatedValues = await handleNext(data, {
      cycle,
      recordTypeIdOppy,
      school
    })

    if (updatedValues?.account !== undefined) {
      setValue('account', updatedValues.account)
    }
    if (updatedValues?.opportunity !== undefined) {
      setValue('opportunity', updatedValues.opportunity)
    }

    if (updatedValues?.cycle !== undefined) {
      setValue('cycle', updatedValues.cycle)
    }
  }

  const handleChangePassword = async(data) => {
    setLoading(true)
    await salesforce.updatePassword(data.password, security_token)

    setFormValue('passwordChanged', true)
    setFormValue('wantChangePassword', false)
    setFormValue('wantLogin', true)
    setFormValue('userExist', true)
    const queryParams = queryString.parse(document.location.search)
    const url = window.location.href
    const [baseUrl] = url.split('?')
    if (queryParams.security_token) {
      delete queryParams.security_token
      const newUrl = `${baseUrl}?${queryString.stringify(queryParams)}`
      window.history.replaceState({}, '', newUrl)
    }
    setLoading(false)
  }

  const handlePasswordForgottenSent = async(data) => {
    setLoading(true)
    const resetpassword = await salesforce.resetPassword(data.account.PersonEmail, 'Inge')
    setFormValue('resetPasswordMailSent', resetpassword)
    setLoading(false)
  }

  const handleOnSubmit = async(data) => {
    setLoading(true)
    if (stageName === 'En cours') {
      const opportunity = {
        Id: data.opportunity.Id,
        StageName: 'Candidature à valider',
      }

      await salesforce.updateOpportunity(opportunity)
    }
    const cycle = await salesforce.getCycle(sessionId)
    const { pages : { enregistre } } = pagesretour.find(page => page.school === school && cycle === page.cycle)
    sessionStorage.clear()
    document.location.href = enregistre
  }

  const onPayLater = async() => {
    setFormValue('payLater', true)
    const values = getValues()
    handleOnSubmit(values)
  }

  return (
    <>
      <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        {currentStep > 2 && (
          <LoadingButton color="primary" startIcon={<NavigatePrevIcon />} onClick={handleBack} loadingPosition="end" variant="outlined">Précédent</LoadingButton>
        )}
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
        {currentStep === 4 && connected && !blocked && !ApplicationPayment && !boursier && (
          <LoadingButton color="primary" loading={loading} onClick={onPayLater} variant="contained">Payer plus tard</LoadingButton>
        )}
      </Grid>
      <Grid item xs={currentStep > 0 ? 5 : 8} sx={{ display: 'flex', justifyContent: currentStep > 0 ? 'flex-start' : 'flex-end', mt: 2 }}>
        {((currentStep !== 1 && currentStep !== 4 && connected && !blocked) || (currentStep === 0 && !connected)) && (
          <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(onNextClick)} variant="contained">Suivant</LoadingButton>
        )}
        {currentStep === 1 && !userExist && !ionisConnectToValidated && !wantChangePassword && token === undefined && (
          <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(onNextClick)} variant="contained">Créer mon compte</LoadingButton>
        )}
        {currentStep === 1 && !userExist && !ionisConnectToValidated && wantChangePassword && (
          <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(handleChangePassword)} variant="contained">Changer mon mot de passe</LoadingButton>
        )}
        {currentStep === 1 && userExist && !connected && !forgotPassword && (
          <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(onNextClick)} variant="contained">Je me connecte</LoadingButton>
        )}
        {currentStep === 1 && userExist && !connected && forgotPassword && (
          <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(handlePasswordForgottenSent)} variant="contained">Réinitialiser mon mot de passe</LoadingButton>
        )}
        {currentStep === 4 && connected && !blocked && (ApplicationPayment || boursier) && stageName === 'En cours' && (
          <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(handleOnSubmit)} variant="contained">Valider ma candidature</LoadingButton>
        )}
        {currentStep === 4 && connected && !blocked && !ApplicationPayment && !boursier && (
          <ButtonPayment keyform={keyform} school={school} />
        )}
      </Grid>
    </>
  )
}

export default Buttons
