import { useFormContext } from "react-hook-form"
import { useStepContext } from "./useStepForm"
import LogoutIcon from '@mui/icons-material/Logout'

const { StepLabel, Step, Stepper, Grid, Tooltip, Button, Typography } = require("@mui/material")

const Navigation = () => {

  const { currentStep, formValues, setCurrentStep, setFormValue, stepNames } = useStepContext()
  const { connected } = formValues
  const { reset } = useFormContext()

  const alreadyAccount = () => {
    setCurrentStep(1)
    setFormValue('userExist', true)
    setFormValue('wantLogin', true)
  }

  const logout = () => {
    // reset({})
    setFormValue('connected', false)
    setFormValue('blocked', false)
    setFormValue('userExist', false)
    setTimeout(() => sessionStorage.clear(), 0)
    setCurrentStep(0)
    reset({})
  }

  return (
    <>
      {(currentStep === 0) && (
        <Grid item xs={12} sx={{ mb: 4 }} alignItems="center" display="flex" justifyContent="left">
          <Typography variant="body2">Vous avez déjà un compte ?&nbsp;&nbsp;</Typography>
          <Button color="primary" onClick={alreadyAccount} size="small" variant="contained">Connectez-vous</Button>
        </Grid>
      )}
      {(currentStep > 1 && connected) && (
        <Grid item xs={12}  sx={{ mb: 4 }} display="flex" justifyContent="right">
          <Tooltip title="Quitter le formulaire">
            <Button  color="primary" onClick={logout} sx={{
                  '& .MuiButton-startIcon': {
                    margin: 0
                  },
                }} startIcon={<LogoutIcon />} variant="outlined">
            </Button>
          </Tooltip>
        </Grid>
      )}
      <Stepper activeStep={currentStep} alternativeLabel sx={{ pt: 2, pb: 2, mb: 2 }}>
        {stepNames.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel
                sx={{
                  '& .MuiStepIcon-root.Mui-completed': {
                    color: '#a7d300', // Couleur verte pour les étapes validées
                  },
                }}
              >{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </>
  )
}

export default Navigation
