import * as Yup from "yup"
import { matchIsValidTel } from "mui-tel-input"

const schemas = [
  Yup.object().shape({
    account: Yup.object().shape({
      AcademicLevel__pc: Yup.string().required(`Le niveau d'étude est requis`),
      FirstName: Yup.string().required('Le prénom est requis').max(64, 'Le prénom doit contenir au maximum 64 caractères'),
      LastName: Yup.string().required('Le nom est requis').max(64, 'Le nom doit contenir au maximum 64 caractères'),
      PersonEmail: Yup.string().email(`Le format de l'adresse e-mail est incorrect`).required(`L'adresse e-mail est requise`).max(40, `L'adresse e-mail doit contenir au maximum 40 caractères`),
      PersonMobilePhone: Yup.string().required('Le mobile est requis').max(64, 'Le téléphone doit contenir au maximum 64 caractères').test('test-mobile-phone', 'Le numéro que vous avez renseigné semble incorrect',
      (val) => {
        return val === '' || matchIsValidTel(val)
      }),
    }),
    opportunity: Yup.object().shape({
      CampusCityWish__c: Yup.string().required('Le campus est requis'),
      City_in_charge__c: Yup.string().required('Le campus est requis'),
      Session__c: Yup.string().required('La session de rentrée est requise'),
    }),
    cycle: Yup.string().required('Le cycle est requis'),
    programme: Yup.string().required(`La formation est requise`),
  }),
  Yup.object().shape({
    account: Yup.object().shape({
      PersonEmail: Yup.string().when('$forgotPassword', {
        is: true,
        then: (schema) => schema.email(`Le format de l'adresse e-mail est incorrect`).required(`L'adresse e-mail est requise`).max(40, `L'adresse e-mail doit contenir au maximum 40 caractères`)
      })
      .when('$wantLogin', {
        is: true,
        then: (schema) => schema.email(`Le format de l'adresse e-mail est incorrect`).required(`L'adresse e-mail est requise`).max(40, `L'adresse e-mail doit contenir au maximum 40 caractères`)
      }),
    }),
    password: Yup.string().when('$forgotPassword', {
      is: false,
      then: (schema) => schema.min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est requis')
    }),
    // password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est requis'),
    passwordConfirmation: Yup.string().when('$userExist', {
      is: false,
      then: (schema) => schema.oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas').required('La confirmation du mot de passe est requise')
    })
  }),
  Yup.object().shape({
    account: Yup.object().shape({
      AcademicLevel__pc: Yup.string().required(`Le niveau d'étude est requis`),
      FirstName: Yup.string().required('Le prénom est requis').max(64, 'Le prénom doit contenir au maximum 64 caractères'),
      LastName: Yup.string().required('Le nom est requis').max(64, 'Le nom doit contenir au maximum 64 caractères'),
      PersonMailingPostalCode: Yup.string().required('Le code postal est requis').max(20, 'Le code postal doit contenir au maximum 20 caractères'),
      PersonMailingStreet: Yup.string().required(`La rue est requise`).max(255, `La rue doit contenir au maximum 255 caractères`),
      PersonMailingCity: Yup.string().required('La ville est requise').max(40, `La ville doit contenir au maximum 40 caractères`),
      PersonMailingCountry: Yup.string().required(`Le pays est requis`),
      PersonMobilePhone: Yup.string().required('Le mobile est requis').max(64, 'Le téléphone doit contenir au maximum 64 caractères').test('test-mobile-phone', 'Le numéro que vous avez renseigné semble incorrect',
        (val) => {
          return val === '' || matchIsValidTel(val)
        }),
      // EstablishmentCountry: Yup.string().required(`Le pays de l'établissement est requis`),
      // EstablishmentCity: Yup.string().required('La ville de l\'établissement est requise'),
      // EstablishmentName: Yup.string().required('Le nom de l\'établissement est requis')
    })
  }),
  Yup.object().shape({
    files: Yup
    .mixed()
    .nullable()
    .test('fileType', 'Seuls les formats JPG, PNG et PDF sont acceptés', (files) => {
      if (!files || files.length === 0) return true
      return (
        files && Array.from(files).every((file) => ['image/jpeg', 'image/png', 'application/pdf'].includes(file.type))
      )
    })
    .test('totalSize', 'La taille totale des fichiers ne doit pas dépasser 20 Mo', (files) => {
      if (!files || files.length === 0) return true
      return (
        files && Array.from(files).reduce((acc, file) => acc + file.size, 0) <= 20 * 1024 * 1024
      )
    }),
    filesUploaded: Yup.array().of(Yup.object())
  }),
  Yup.object().shape({
    CGV: Yup.boolean()
    .test(
      'cgv-required',
      'Vous devez accepter les conditions générales de vente',
      (value, context) => {
        const { account : { Grant_Student__c }, opportunity : { ApplicationPayment__c } } = context.parent
        if (!Grant_Student__c) {
          return value === true || ApplicationPayment__c === true
        }
        return true
      }
    )
  })
]

export default schemas
